<template>
  <div class="result app_content">
    <div class="warp_body">
      <div class="notFound">
        <van-image :src="require('@/icons/common/404.png')"></van-image>
      </div>
    </div>
  </div>
</template>

<script>


</script>
<style lang="less">
.app_content {
  .warp_body {

    .notFound{
      position: absolute;
      top: 20%;
      transform: translateX(-50%);
      left: 50%;
    }
  }
}
</style>
